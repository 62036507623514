<template>
    <dashboard-layout>
        <v-card class="mt-4">
            <v-card-title>
                <v-row>
                    <v-col>
                        {{ gig.title }}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'artist.gigs.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="gig.livestream_url"
                                label="Livestream Url"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn color="success" class="mr-4" @click="save">
                                Save
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </dashboard-layout>
</template>

<script>
import Gig from "@/models/Gig";
import DashboardLayout from "@/layouts/DashboardLayout";

export default {
    name: "gigs-show",
    components: {DashboardLayout},
    data: function () {
        return {
            gig: {
                livestream_url: null
            }
        }
    },
    async mounted() {
        const gigId = this.$route.params.id
        this.gig = await Gig.include('venue').find(gigId)
    },
    methods: {
        save() {
            this.gig.save()
        }
    }
}
</script>

<style scoped>

</style>
